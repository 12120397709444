// React Imports
import React from 'react';

import 'components/card/service/serviceCard.style.scss';
import Helper from '../../../core/helper/helper';
import RightArrowButton from '../../button/rightArrowButton';
import ServiceFeedbackCard from './serviceFeedbackCard';
import CheckInDate from './checkInDate';
import CheckOutDate from './checkOutDate';
import CardCorner from './cardCorner';
import CardItemInformation from '../equipment/CardItemInformation';
import CardServiceState from './CardServiceState';
import ServiceNotificationWidgets from './serviceNotificationWidgets';

class CardService extends React.Component {

  constructor(props) {
    super(props);
    const {
      isLiteVersion, isCompact, isAppointment,
      data: { id, item, phase, state, allow_cancel, allow_approve,
         date_start, old_date, date_end, time, old_time, feedback, schedule, service_toast_state,
         message_toast_state, show_has_internal_notes_icon}
    } = this.props;
    this.refInput = React.createRef();
    this.state = {
      id: id,
      item: Helper.initialItemState(item, isLiteVersion, isAppointment),
      phase: phase,
      state: {
        bars: state?.bars,
        name: state?.bars === 2.5 && !allow_approve ? 'Aguarda aprovação' : state?.name,
      },
      allowCancel: allow_cancel,
      allowApprove: allow_approve,
      dateStart: Helper.printDate(date_start),
      old_date: old_date,
      time: time,
      old_time: old_time,
      dateEnd: Helper.printDate(date_end),
      feedback: feedback,
      schedule: schedule,
      isCompact: isCompact || false,
      stateAction: allow_approve, //state.bars === 2.5, // aguarda aprovação
      stateCheck: state.bars === 4, // aguarda cliente
      showUnreadCheck: service_toast_state === 'EXPANDED', // aguarda cliente expandido
      stateMessage: message_toast_state !== 'IGNORE' && !!message_toast_state, // tem mensagem de prestador
      showUnreadMessage: message_toast_state === 'EXPANDED',
      showInternalNotes: show_has_internal_notes_icon,
      isAppointment: isAppointment
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const {
        isLiteVersion, isCompact, isAppointment,
        data: { id, item, phase, state, allow_cancel, allow_approve, date_start, old_date, date_end, time, old_time, feedback, schedule, service_toast_state, message_toast_state, show_has_internal_notes_icon }
      } = this.props;
      
      this.setState({
        id: id,
        item: Helper.initialItemState(item, isLiteVersion, isAppointment),
        phase: phase,
        state: {
          bars: state?.bars,
          name: state?.bars === 2.5 && !allow_approve ? 'Aguarda aprovação' : state?.name,
        },
        allowCancel: allow_cancel,
        allowApprove: allow_approve,
        dateStart: Helper.printDate(date_start),
        old_date: old_date,
        time: time,
        old_time: old_time,
        dateEnd: Helper.printDate(date_end),
        feedback: feedback,
        schedule: schedule,
        isCompact: isCompact || false,
        stateAction: allow_approve, //state.bars === 2.5, // aguarda aprovação
        stateCheck: state.bars === 4, // aguarda cliente
        showUnreadCheck: service_toast_state === 'EXPANDED', // aguarda cliente expandido        
        stateMessage: message_toast_state !== 'IGNORE' && !!message_toast_state,
        showUnreadMessage: message_toast_state === 'EXPANDED',
        showInternalNotes: show_has_internal_notes_icon
      });
    }
  }

  handlePressEvent = () => {
    this.props.onClick(this.state.id);
  }

  validateSubmition = (feedback, isSubmition) => {
    if (isSubmition) {
      this.props.callback(this.state.id, feedback);
    }
  }

  renderCheckinDate = (dateStart, schedule) => {
    const { state: { bars } } = this.state;
    return <CheckInDate data={{bars, dateStart, schedule, dateStartState: this.state.dateStart}}/>
  }

  renderCheckoutDate = (dateEnd) => {
    const { state: { bars } } = this.state;
    return <CheckOutDate data={{bars, dateEnd, dateEndState: this.state.dateEnd}}/>
  }

  renderItemInformation = (isCompact) => {
    if (isCompact) {
      return <></>;
    }
    const { item: { title, subtitle } } = this.state;
    return <CardItemInformation data={{ title, subtitle }}/>
  }

  renderCardCorner = (bars, feedback) => {
    return <CardCorner data={{bars, feedback}}/>
  }

  renderCardServiceState = () => {
    const { data: {date_start, date_end, schedule}} = this.props;
    const { state, dateStart, old_date, dateEnd, time, old_time, isAppointment } = this.state;
    return (
      <CardServiceState
        data={{
          dateStart: date_start,
          old_date: old_date,
          schedule,
          dateEnd: date_end,
          time: time,
          old_time: old_time,
          state,
          dateStartState: dateStart,
          dateEndState: dateEnd,
          isAppointment: isAppointment,
        }}
        isSmall={true}
      />
    );
  }

  renderCardImage = (isCompact, item) => {
    if (isCompact) {
      return <></>;
    }

    return (
      <div className="card-service-image-container">
        <img
          src={item.image}
          alt="service_image"
          className={Helper.isLiteVersion() ? "pixelized" : ""}
          onError={(e) => (e.target.src = Helper.defaultImage())}
        />
      </div>
    );
  }

  render() {
    const { data: { feedback } } = this.props;
    const { state, item, isCompact, stateAction, stateCheck, stateMessage, showUnreadCheck, showUnreadMessage, allowApprove, showInternalNotes } = this.state;
    const styleClass =  isCompact ? 'is-compact' : '';

    if (state.bars === 5 && !feedback && !isCompact) {
      return (
        <ServiceFeedbackCard data={this.state} onClick={this.handlePressEvent}
          callback={this.validateSubmition} />
      );
    }

    return (
      <div className={`card-service-main-container p-2 ${styleClass}`} onClick={this.handlePressEvent}>
        <ServiceNotificationWidgets
            data={{stateAction, stateCheck, stateMessage, showUnreadCheck, showUnreadMessage, showInternalNotes, allowApprove, isCompact}}
            goToDetails={this.handlePressEvent}/>  
        {this.renderCardCorner(state.bars, feedback)}
        {this.renderItemInformation(isCompact)}
        {this.renderCardImage(isCompact, item)}
        {this.renderCardServiceState()}
        <div className='d-none d-md-block'><RightArrowButton /></div>
      </div>
    );
  }
}

export default CardService;