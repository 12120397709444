import React, { Fragment } from 'react';
//import ReactGA from 'react-ga';
import classNames from "classnames"
import { withRouter } from 'react-router';
import Cookies from 'universal-cookie';

import Emitter from 'core/services.js';
import Labels from '../variables/labels.js';

import {
  getCompletedServices, getInProgressServices, getScheduleServices,
  getShowcaseWorkDetails, getShowcaseWorkDetailsCal, provideServiceFeedback
} from '../api/routes/api.route.js';
import Loader from '../components/loader/loader';
import CardService from '../components/card/service/serviceCard.jsx';
import Grid from 'components/list/grid/grid.jsx';
import Modal, { Information } from 'components/modal/modal.jsx';
import Helper from '../core/helper/helper';
import { HelperSchedule } from './schedule/core/helpers/helperSchedule.js';
import { Helmet } from 'react-helmet';
import { getItemList, postLogLite } from '../api/routes/api.route.js';
import LocalData from '../core/localData.js';
import ConfirmationCTA from '../components/button/ConfirmationCTA.jsx';
import ScheduleService from './schedule/core/services/schedule.service.js';
// Import Swipe
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper/core';
// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/components/scrollbar/scrollbar.scss'; // Scrollbar styles
import BVService from '../core/services/services-view.service.js';
import { analyseUrlShowcaseAnalytics } from '../core/services/analytics.service.js';
import VideoTutorial from '../components/modal/samples/videoTutorial.jsx';
import { SIDEBAR } from '../assets/images/images.js';
import IAgreeBox from '../components/iagree/iagreeBox.jsx';
import CompanyService from '../core/services/company.service.js';

// Enable the Scrollbar module
SwiperCore.use([Scrollbar]);

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this._isMounted = false;
    this.timer = null;
    this.refModal = React.createRef();
    this.listviewRef = React.createRef();
    const query = this.props.location.search;
    this.token = new URLSearchParams(query).get('token') || LocalData.liteData?.token;
    this.token_cal = new URLSearchParams(query).get('token_cal') || LocalData.liteData?.token_cal;
    this.isLiteVersion = Helper.isLiteVersion();
    this.isLiteWithoutToken = this.isLiteVersion && !this.token && !this.token_cal;

    this.state = {
      scheduleWorks: undefined,
      progressWorks: null,
      completedWorks: undefined,
      progressWorksState: false,
      scheduleWorksState: false,
      completedWorksState: false,
      // scrollPosition: {
      //   scheduleScrollPos: ServicesViewService.scheduleScrollPos,
      //   progressScrollPos: ServicesViewService.progressScrollPos,
      //   completedScrollPos: ServicesViewService.completedScrollPos,
      // },
      listScrollPosition: sessionStorage.getItem('listScrollPosition'),
      toggled: false,
      showcaseWork: null,
      worksLoadError: {
        progress: false,
        schedule: false,
        completed: false,
      },
      loadingState: {
        progress: true,
        schedule: true,
        completed: true
      },
      isLoading: true,
      swiperLoaded: BVService.swiperLoaded,
      firstTimeSwiperLoading: true,
      currentSlide: 0,
      isMobile: false,
      rerenderKey: 0,
    };
    this.baseState = { ...this.state };
    this.goToFeedback = new URLSearchParams(this.props.location.search).has('feedback');
  }

  componentDidMount() {
    this.mountButtonScrollPosition();
    this.handleResize();
    if (this.isLiteVersion) { this.mountLiteVersionData(); }
    if (!this.isLiteVersion && LocalData.terms_accepted) {
      this.cookies.remove('liteToken', Helper.cookiesLiteConfig);
      this.cookies.remove('liteEmail', Helper.cookiesLiteConfig);
      this.cookies.remove('litePhone', Helper.cookiesLiteConfig);
      this.cookies.remove('userId', Helper.cookiesLiteConfig);
      this.cookies.remove('isCalToken', Helper.cookiesLiteConfig);
      this.cookies.remove('kssreturncompany', Helper.cookiesLiteConfig);
      this.getServices();
    }
    this.refreshPage();

    if (Helper.isIOSWebview()) {
      try {
        window.webkit.messageHandlers.saveLastUrl.postMessage( {url: window.location.href} );
      } catch (error) {
        console.error(error);
      }
    }
    if (Helper.isAndroidWebview()) {
      try {
        window.Android.saveLastUrl(window.location.href);
      } catch (error) {
        console.error(error);
      }
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.listenToScroll);
    this.listElement.removeEventListener('scroll', this.listenToListScroll);
    window.removeEventListener('resize', this.handleResize);
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }


  handleResize = () => {
    const toastElem = document.querySelector(".Toastify__toast-container--top-right");
    if (toastElem && window.innerWidth <= 768) {
      toastElem.style.top = "108px";
    }
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  refreshPage = () => {
    if (!this.isLiteWithoutToken) {
      setTimeout(() => {
        if (this.isLiteVersion) { this.mountLiteVersionData(); }
        if (!this.isLiteVersion && LocalData.terms_accepted) {
          this.getServices();
          this.getUserItems();
          CompanyService.getCompaniesCount();
        }
        this.setState((prevState) => ({
          rerenderKey: prevState.rerenderKey + 1,
        }), () => {
          this.listenToScroll();
        }); // Update key to trigger re-render
        this.refreshPage(); // Reschedule after processing the current check's result
      }, 300000);
    }
  }

  resetForm = () => {
    this.setState({...this.baseState})
  }

  mountLiteVersionData() {
    if (this.token) {
      this.cookies.set('liteToken', this.token, Helper.cookiesLiteConfig);
      if (this.email) { this.cookies.set('liteEmail', this.email, Helper.cookiesLiteConfig); }
      if (this.phone) { this.cookies.set('litePhone', this.phone, Helper.cookiesLiteConfig); }
      const companyDomain = Helper.companyDomain();
      if (!companyDomain.includes("asuaempresa")) {
        this.cookies.set('kssreturncompany', companyDomain, Helper.cookiesLiteConfig);
      }

    }
    if (this.token_cal) {
      this.cookies.set('liteToken', this.token_cal, Helper.cookiesLiteConfig);
      if (this.email) { this.cookies.set('liteEmail', this.email, Helper.cookiesLiteConfig); }
      if (this.phone) { this.cookies.set('litePhone', this.phone, Helper.cookiesLiteConfig); }
      const companyDomain = Helper.companyDomain();
      if (!companyDomain.includes("asuaempresa")) {
        this.cookies.set('kssreturncompany', companyDomain, Helper.cookiesLiteConfig);
      }

      this.cookies.set('isCalToken', true, Helper.cookiesLiteConfig); //means that we are in calendar context
    }
    if (LocalData.liteData) {
      const bars = LocalData.liteData.state.bars;
      this.setState({
        showcaseWork: LocalData.liteData,
        progressWorksState: bars > 0 && bars < 5,
        scheduleWorksState: bars === 0,
        completedWorksState: bars === 5,
        isLoading: false,
      });
    } else {
      if(this.token) {this.getShowcaseDetails(this.token, this.cookies.get('sessionToken'));}
      if(this.token_cal) {this.getShowcaseDetailsCal(this.token_cal, this.cookies.get('sessionToken'));}
    }
    if (this.timer) { clearTimeout(this.timer); }
  }

  mountButtonScrollPosition() {
    const { listScrollPosition } = this.state
    this._isMounted = true;
    this.listElement = document.getElementById('scroll-container');
    this.mobileElement = window.document.documentElement;

    if (window.innerWidth <= 768) {
      switch (BVService.swiperSlide) {
        case 0:
          this.scrollTo(BVService.progressScrollPos);
          break;
        case 1:
          this.scrollTo(BVService.scheduleScrollPos);
          break;
        case 2:
          this.scrollTo(BVService.completedScrollPos);
          break;
        default:
          break;
      }
    }
    window.addEventListener("scroll", this.listenToScroll);
    setTimeout(() => {
      if (listScrollPosition) {
        this.scrollListToTop(listScrollPosition);
      }
      this.listElement.addEventListener("scroll", this.listenToListScroll);
    }, 500);
  }

  scrollTo(pos) {
    setTimeout(() => {
      this.mobileElement.scrollTo(0, pos);
    }, 10);
  }

  scrollListToTop(pos) {
    this.listElement.scrollTo(0, pos);
  }

  listenToScroll = () => {
    if (this.mobileElement.scrollTop || this.mobileElement.scrollTop === 0) {
      sessionStorage.removeItem('listScrollPosition');
      BVService.setScrollPosition(this.mobileElement.scrollTop);

      this.winScroll = this.mobileElement.scrollTop;
      BVService.setSwiperClasses(this.winScroll);

      if (this.listScroll >= 200) {
        this.setState({ toggled: true });
      } else {
        this.setState({ toggled: false });
      }
    }
  }

  listenToListScroll = () => {
    this.listScroll = this.listElement.scrollTop;
    if (this.listScroll && !this.state.isMobile) {
      sessionStorage.setItem('listScrollPosition', this.listScroll);
      sessionStorage.removeItem('scrollPosition');
      if (this.listScroll >= 200) {
        this.setState({ toggled: true });
      } else {
        this.setState({ toggled: false });
      }
    }
  }

  sendLog = (content) => {
    const body = { content };
    postLogLite(body);
  }

  /**
  * Pedido para retornar a obra a mostrar em caso de versão Lite
  *
  * @param {string} id - id para a obra
  */
  getShowcaseDetails(id, token) {
    this.sendLog(window.location.href);
    getShowcaseWorkDetails(id, token).then(value => {
      if (value) {
        if (value?.code === 1 && value?.details) {
          const { item } = value.details;
          const parsedWork = {
            ...value.details,
            item: {
              ...item,
              image: Helper.x64Image(item?.image),
            },
          };
          LocalData.liteData = parsedWork;
          if (value.details.user_id) {
            this.cookies.set("userId", value.details.user_id, Helper.cookiesLiteConfig);
          }
          if (value.details.email) {
            this.cookies.set("liteEmail", value.details.email, Helper.cookiesLiteConfig);
          }
          if (value.details.phone) {
            this.cookies.set("litePhone", value.details.phone, Helper.cookiesLiteConfig);
          }
          setTimeout(() => {
            this.props.history.push({
              pathname: "service-details-lite",
              search: new URLSearchParams(window.location.search).toString(),
            });
          }, 100);
        } else if (value?.code === 400 && value?.error_code === 0) {
          this.renderInfoModal("", value.message);
          this.setState({ isLoading: false });
        } else if (value?.code === 3 || value?.code === 2) {
          Emitter.emit('TOAST_LITEVERSION_DISABLE', true);
          this.renderModal(
            <Information
              title="Deseja aceder ao histórico da sua última intervenção?"
              text={Labels.liteGenericMessage}
              confirmText={Helper.getLiteRegisterButton()}
              onClick={() => Helper.logoutFromLite()}
            />
          );
          this.setState({ isLoading: false });
        } else if (value?.code === 5) {
          this.setState({ isLoading: false });
          if (this.goToFeedback) {
            analyseUrlShowcaseAnalytics(window.location.href, true, value.work_id); //Send analytics before redirect
            window.location.href = `${process.env.PUBLIC_URL}/service-details/${value?.work_id}?s=I&t=0&feedback=t`;
          } else {
            analyseUrlShowcaseAnalytics(window.location.href, true, value.work_id);
            window.location.href = `${process.env.PUBLIC_URL}/service-details/${value?.work_id}?s=I&t=0`;
          }
        } else {
          this.setState({ isLoading: false });
          this.renderGenericError();
        }
      }
      else {
        this.setState({ isLoading: false });
        this.renderGenericError();
      }
    });
  }

/**
  * Pedido para retornar o agendamento a mostrar em caso de versão Lite
  *
  * @param {string} id - id para a obra
  */
  getShowcaseDetailsCal(id, token) {
    this.sendLog(window.location.href);
    getShowcaseWorkDetailsCal(id, token).then(value => {
      if (value) {
        if (value?.code === 1 && value?.details) {   //Estava value===1. Martelado para testar
          const { item, date_start, date_end } = value.details;
          const formattedDateStart = date_start ? ScheduleService.convertDateFromISOPendingAppointment(date_start) : null;
          const formattedTime = date_start ? ScheduleService.getTimeFromISO(date_start) : null;
          const formattedCalendarShareDateStart = date_start ? ScheduleService.convertDateFromISOCalendarShare(date_start) : null;
          const formattedCalendarShareDateEnd = date_end ? ScheduleService.convertDateFromISOCalendarShare(date_end) : null;
          const { token, ...detailsWithoutToken } = value.details;
          const parsedWork = {
            ...detailsWithoutToken,
            date_start: formattedDateStart,
            time: formattedTime,
            state: {name: HelperSchedule.getAppointmentStateBarsDescription(value.details), bars:0},
            shareCalendar_start_time: formattedCalendarShareDateStart,
            shareCalendar_end_time: formattedCalendarShareDateEnd,
            item: {
              ...item,
              image: Helper.x64Image(item?.photo)
            },
            token_cal: token
          };
          LocalData.liteData = parsedWork;
          if (value.details.user_id) {
            this.cookies.set('userId', value.details.user_id, Helper.cookiesLiteConfig);
          }
          if (value.details.email) {
            this.cookies.set('liteEmail', value.details.email, Helper.cookiesLiteConfig);
          }
          if (value.details.phone) {
            this.cookies.set('litePhone', value.details.phone, Helper.cookiesLiteConfig);
          }
          setTimeout(() => {
            this.props.history.push({
              pathname: 'service-details-lite',
              search: new URLSearchParams(window.location.search).toString(),
            });
          }, 100);
        } else if (value?.code === 400 && value?.error_code === 0) {
          this.renderInfoModal('', value.message);
          this.setState({ isLoading: false });
        } else if (value?.code === 3 || value?.code === 2 || (value?.code===1 && !value.details)) {
          Emitter.emit('TOAST_LITEVERSION_DISABLE', true);
          this.renderModal(
            <Information
              title="Deseja aceder ao histórico da sua última intervenção?"
              text={Labels.liteGenericMessage}
              confirmText={Helper.getLiteRegisterButton()}
              onClick={() => (Helper.logoutFromLite())}
            />
          );
          this.setState({ isLoading: false });
        } else if (value?.code === 5) {
          this.setState({ isLoading: false });
          analyseUrlShowcaseAnalytics(window.location.href, true, value.work_id); //Send analytics before redirect
          window.location.href = `${process.env.PUBLIC_URL}/service-details/${value?.work_id}?s=I&t=0`;
        } else {
          this.setState({ isLoading: false });
          window.location.href = `${process.env.PUBLIC_URL}`;
        }
      }
      else {
        this.setState({ isLoading: false });
        this.renderGenericError();
      }
    });
    this.setState({ isLoading: false });
  }

  /**
   * Pedido para retornar dos os seriços que foram agendados
   *
   * @param {number} id - id do serviço
   * @param {string} feedback - objecto com o feedback (rating, observação)
   */
  sendServiceFeedback = (id, feedback) => {
    provideServiceFeedback(this.cookies.get('sessionToken'), id, feedback).then(value => {
      if (value) {
        if (value.code) {
          this.renderModal(<Information
            title="Pedido Sem Sucesso"
            text={value.message}
            onClick={() => {
              this.getServices();
              this.refModal.current.closeModal();
            }}
          />);
        } else {
            this.getServices();
            CompanyService.getCompaniesCount();
        }
      } else {
        this.renderModal(<Information
          title="Pedido Sem Sucesso"
          text={'Foi encontrado um problema durante o pedido, por favor tente mais tarde'}
          onClick={() => { this.refModal.current.closeModal(); }}
        />
        );
      }
    });
  }

  validateFeedback = (id, feedback) => {
    if (!this.isLiteVersion) {
      this.sendServiceFeedback(id, feedback);
    } else {
      this.renderModal(
        <Information
          title={Labels.liteSendFeedbackTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => (Helper.logoutFromLite())}
        />
      );
    }
  }

  /**
   * Evento de click do serviço selecionado
   *
   * @param {number} id - id do serviço
   * @param {string} info - objecto da serviço selecionado
   */
  handlePressEvent = (id) => {
    this._isMounted = false;
    if (this.isLiteVersion) {
      const { search } = this.props.location;
      this.props.history.push(`/service-details-lite${search}`);
    } else {
      this.props.history.push(`/service-details/${id}?s=I&t=0`);
    }
  }

  getUserItems() {
    getItemList(this.cookies.get('sessionToken')).then(value => {
      if (value && !value.code) {
        LocalData.equipmentsData = value.items;
      }
    })
  }

  getServices() {
    /**
    * Pedido para retornar dos os seriços que se encontram em curso
    *
    * @param {string} token - token de sessão
    */
    this.getInProgress();
    /**
    * Pedido para retornar dos os seriços que foram agendados
    *
    * @param {string} token - token de sessão
    */
    this.getSchedules();
    /**
    * Pedido para retornar dos os seriços que já foram conlcuidos
    *
    * @param {string} token - token de sessão
    */
    this.getCompleted();
  }

  setGridState(length, service) {
    if (sessionStorage.getItem(`${service}GridState`) != null) {
      return JSON.parse(sessionStorage.getItem(`${service}GridState`));
    } else {
      return length > 0 ? true : false;
    }
  }

  getInProgress() {
    getInProgressServices(this.cookies.get('sessionToken')).then(value => {
        if (value) {
          if (value.code) {
            this.setState({
              progressWorksState: true,
              worksLoadError: {
                ...this.state.worksLoadError,
                progress: true
              },
              loadingState: {
                ...this.state.loadingState,
                progress: false,
              },
              isLoading: false
            })
          }
          else {
            this.setState({
              progressWorks: value.works,
              progressWorksState: this.setGridState(value.works.length, 'progress'),
              worksLoadError: {
                ...this.state.worksLoadError,
                progress: false
              },
              loadingState: {
                ...this.state.loadingState,
                progress: false,
              },
              isLoading: false
            });
            LocalData.servicesData['progressWorks'] = value.works;
            this.cookies.set('prevCompanyUrl', window.location.href, Helper.cookiesConfig);
          }
        }
        else {
          this.renderModal(<Information title="Pedido Sem Sucesso" text={'Foi encontrado um problema durante o pedido, por favor tente mais tarde'} onClick={() => { this.refModal.current.closeModal(); }} />);
        }
      });

    if (!LocalData.servicesData?.progressWorks) {
      return;
    }
    
    this.setState({
      progressWorks: LocalData.servicesData?.progressWorks,
      progressWorksState: this.setGridState(LocalData.servicesData?.progressWorks.length, 'progress'),
      worksLoadError: {
        ...this.state.worksLoadError,
        progress: false
      },
      loadingState: {
        ...this.state.loadingState,
        progress: false,
      },
      isLoading: false
    });
  }

  getSchedules() {
    getScheduleServices(this.cookies.get('sessionToken')).then(value => {
      if (value) {
        if (value.code) {
          this.setState({
            scheduleWorksState: true,
            worksLoadError: {
              ...this.state.worksLoadError,
              schedule: true
            },
            loadingState: {
              ...this.state.loadingState,
              schedule: false,
            },
            isLoading: false
          })
        }
        else {
          this.setState({
            scheduleWorks: value.works,
            scheduleWorksState: this.setGridState(value.works.length, 'schedule'),
            worksLoadError: {
              ...this.state.worksLoadError,
              schedule: false
            },
            loadingState: {
              ...this.state.loadingState,
              schedule: false,
            },
            isLoading: false
          });
          LocalData.servicesData['scheduleWorks'] = value.works;
          this.cookies.set('prevCompanyUrl', window.location.href, Helper.cookiesConfig);
        }
      }
      else {
        this.renderGenericError();
      }
    });

    if (!LocalData.servicesData?.scheduleWorks) {
      return;
    }
    
    this.setState({
      scheduleWorks: LocalData.servicesData?.scheduleWorks,
      acheduleWorksState: this.setGridState(LocalData.servicesData?.scheduleWorks.length, 'progress'),
      worksLoadError: {
        ...this.state.worksLoadError,
        schedule: false
      },
      loadingState: {
        ...this.state.loadingState,
        schedule: false,
      },
      isLoading: false
    });
  }

  getCompleted() {
    getCompletedServices(this.cookies.get('sessionToken')).then(value => {
      if (value) {
        if (value.code) {
          this.setState({
            completedWorksState: true,
              worksLoadError: {
              ...this.state.worksLoadError,
              completed: true
            },
            loadingState: {
              ...this.state.loadingState,
              completed: false,
            },
            isLoading: false
          })
        }
        else {
          this.setState({
            completedWorks: value.works,
            completedWorksState: this.setGridState(value.works.length, 'completed'),
            worksLoadError: {
              ...this.state.worksLoadError,
              completed: false
            },
            loadingState: {
              ...this.state.loadingState,
              completed: false,
            },
            isLoading: false
          });
          LocalData.servicesData['completedWorks'] = value.works;
          this.cookies.set('prevCompanyUrl', window.location.href, Helper.cookiesConfig);
        }
      }
      else {
        this.renderGenericError();
      }
    });

    if (!LocalData.servicesData?.completedWorks) {
      return;
    }
    
    this.setState({
      completedWorks: LocalData.servicesData?.completedWorks,
      completedWorksState: this.setGridState(LocalData.servicesData?.completedWorks.length, 'completed'),
      worksLoadError: {
        ...this.state.worksLoadError,
        completed: false
      },
      loadingState: {
        ...this.state.loadingState,
        completed: false,
      },
      isLoading: false
    });
  }

  gridState(length) {
    return length > 0;
  }

  handlerTopUpButton = (event) => {
    event.stopPropagation();
    if (this.listScroll) {
      this.listElement.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (this.winScroll) {
      this.mobileElement.scrollTo({ top: 0, behavior: 'smooth'});
    }
  }

  renderShowcaseContainer = (work, isAppointment) => {
    if (!work || !this.isLiteVersion) { return; }
    const { isMobile } = this.state;
    const companyName = Helper.companyName();
    return (
      <Fragment>
        {isMobile && <div style={{height: "24px"}}></div>}
        <CardService
          data={work}
          isAppointment={isAppointment}
          isLiteVersion={true}
          onClick={() => this.handlePressEvent(this.token, work)}
          callback={(id, rating) => this.validateFeedback(id, rating)}
        />

        {!isMobile && this.renderAddNewAppointmentButton(companyName)}
      </Fragment>
    );
  }

  handleEventVideoTutorial = () => {
    this.renderModal(
      <VideoTutorial
        title={"Tutorial Cliente"}
        onClick={() => {
          this.refModal.current.closeModal();
        }}
        videoId="ZgPUE68tg-U" // Replace with your YouTube video ID
      />,
      false,
      true
    );
  };

  renderLiteMessage = (gridId) => {
    return (
      <React.Fragment>
        <p className='mt-3'>{Labels.liteGenericMessage}</p>
        <ConfirmationCTA
          confirmText={Helper.getLiteRegisterButton()}
          confirmationHandle={() => Helper.logoutFromLite()}
          declineHandle={() => Emitter.emit('LIST_GRID_STATE_CHANGED', gridId)}
        />
        <div className='mt-3'>
          <a className='link-container' href={Helper.linkToAppStore()}
            target="_blank" rel="noopener noreferrer">
            Instalar App Keymaster Cliente
          </a>
          <button className='link-container showcase-message p-1'
            onClick={this.handleEventVideoTutorial}
            style={{ width: '100%', background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
          >
            <img src={SIDEBAR.videoDemoIcon} alt="Vídeo Demo Icon" className='showcase-icon' />
            Vídeo de Demonstração
          </button>
        </div>
      </React.Fragment>
    );
  }

  renderLiteMessageContainer = (title, gridId) => {
    return (
      <div className="welcome-message-container mt-0">
        <div className="ask-container">
          <div className="ask">
            <h3> {title}</h3>
            {this.renderLiteMessage(gridId)}
            
          </div>
        </div>
      </div>
    );
  }

  renderAddNewAppointmentButton = (companyName) => {
    const { isMobile } = this.state;
    const className = classNames("cta-bottom-container d-flex justify-content-center flex-column", {"mt-4 mb-1": !isMobile, "mb-4": isMobile});
    const schedulerLabel = `${Labels.schedulers.newscheduler} ${companyName}`;

    return (
      <div className={className}>
        <div
          className="cta-button-primary d-flex d-inline-flex px-3 justify-content-center align-items-center"
          role="button"
          onClick={() => {
            Emitter.emit("SIDEBAR_OPEN", "btnScheduleShowMyEquipments");
          }}
        >
          <span className="text-multiline-truncate">{schedulerLabel}</span>
        </div>

        <div className="link-container mt-4 text-center">
          {!Helper.isLiteVersion() && (
            <span
              onClick={() => {
                Emitter.emit("SIDEBAR_OPEN", "btnScheduleOldAppointments");
              }}
            >
              {Labels.sidebarScheduleMessage().oldAppointmentsLink}
            </span>
          )}
        </div>
      </div>
    );
  }

  renderScheduleList = () => {
    const { scheduleWorks, showcaseWork, worksLoadError, isMobile } = this.state;
    const companyName = Helper.companyName().replace(/ /g, '\u00A0');
    const schedulerLabel = `${Labels.schedulers.newscheduler} ${companyName}`;

    if (this.isLiteVersion) {
      const isAppointment=true;
      if (showcaseWork?.state?.bars === 0) {
        return this.renderShowcaseContainer(showcaseWork, isAppointment);
      } 
    }

    if (worksLoadError.schedule) {
      return (
        <div className="empty-services-container" style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: Labels.errorLoadServices}}>
        </div>
      );
    }

    if (Helper.listIsEmpty(scheduleWorks)) {
      return (isMobile 
        ? <div className="counter-message-list-mobile-nocounter">{Labels.counterMessages.noschedulers.replace(Labels.counterMessages.companyname, companyName)}</div>
        : <>
            <div className="empty-services-container">{Labels.counterMessages.noschedulers.replace(Labels.counterMessages.companyname, companyName)}</div>
            <div className="cta-bottom-container d-flex justify-content-center flex-column">
              <div
                className="cta-button-primary d-flex d-inline-flex px-3 justify-content-center align-items-center"
                role="button"
                onClick={() => { Emitter.emit('SIDEBAR_OPEN', 'btnScheduleShowMyEquipments'); }}
              >
                <span className="text-multiline-truncate">{schedulerLabel}</span>
              </div>
              <div className="link-container mt-4 text-center">
                <span onClick={() => { Emitter.emit('SIDEBAR_OPEN', 'btnScheduleOldAppointments'); }}>
                  {" "}
                  {Labels.sidebarScheduleMessage().oldAppointmentsLink}
                </span>
              </div>
            </div>
        </>
      );
    }

    return (
      <>
      {isMobile && <div style={{height: "24px"}}></div>}
      {scheduleWorks.map((prop, index) => {
          const isAppointment=true;
          return (
            <CardService key={index} data={prop} isAppointment={isAppointment} type="schedule"
              onClick={() => this.handlePressEvent(prop.id, prop)}
            />
          );
        }) 
      }

      {isMobile && <div style={{height: "90px"}}></div>}

      {!isMobile && this.renderAddNewAppointmentButton(companyName)
      }
    </>)
  }

  renderProgressList = () => {
    const { progressWorks, showcaseWork, worksLoadError, isMobile } = this.state;
    const companyName = Helper.companyName();

    if (this.isLiteVersion) {
      const title = Labels.liteProgressTitle;
      if (showcaseWork?.state?.bars > 0 && showcaseWork.state?.bars < 5) {
        return this.renderShowcaseContainer(showcaseWork);
      } else {
        return this.renderLiteMessageContainer(title, 'progress');
      }
    }
    if (worksLoadError.progress) {
      return (
        <div className="empty-services-container" style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: Labels.errorLoadServices}}>
        </div>
      );
    }

    if (Helper.listIsEmpty(progressWorks)) {
      return (isMobile 
        ? <div className="counter-message-list-mobile-nocounter">{Labels.counterMessages.noservices.replace(Labels.counterMessages.companyname, companyName)}</div>
        : <div className="empty-services-container">{Labels.counterMessages.noservices.replace(Labels.counterMessages.companyname, companyName)}</div>
      );
    }

    return (
      <div className="h-100">
        {isMobile && <div style={{height: "24px"}}></div>}
        {progressWorks.map((prop, index) => {
          return (
            <CardService
              key={index}
              data={prop}
              onClick={() => this.handlePressEvent(prop.id, prop)}
              callback={this.validateFeedback}
            />
          );
        })}
      </div>
    );
  }

  renderHistoryList = () => {
    const { showcaseWork, completedWorks, worksLoadError, isMobile } = this.state;
    const countToFeedbackServices = Helper.countToFeedbackServices(completedWorks, showcaseWork);
    const companyName = Helper.companyName();
    const pluralSuffix = countToFeedbackServices > 1 ? 's' : '';

    if (this.isLiteVersion) {
      const title = Labels.liteHistoryTitle;
      if (showcaseWork?.state?.bars === 5) {
        return this.renderShowcaseContainer(showcaseWork);
      } else {
        return this.renderLiteMessageContainer(title, 'completed');
      }
    }

    if (worksLoadError.completed) {
      return (
        <div className="empty-services-container" style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: Labels.errorLoadServices}}>
        </div>
      );
    }
    
    if (Helper.listIsEmpty(completedWorks)) {
      return (isMobile 
        ? <div className="counter-message-list-mobile-nocounter">{Labels.counterMessages.noservicesclassification.replace(Labels.counterMessages.companyname, companyName)}</div> 
        : <div className="empty-services-container">{Labels.counterMessages.noservicesclassification.replace(Labels.counterMessages.companyname, companyName)}</div>
      );
    }

    const message = Labels.counterMessages.classification
      .replace(Labels.counterMessages.companyname, companyName)
      .replace(/{{plural}}/g, pluralSuffix);
    return (<>
      {isMobile
        ? countToFeedbackServices  
          ? <div className="counter-message-list-mobile">{countToFeedbackServices} {message}</div> 
          : ''
        : <div className="counter-message-list-desktop" />
      }
      {isMobile && !countToFeedbackServices && <div style={{height: "24px"}}></div>}
      {completedWorks.map((prop, index) => {
        return (
          <CardService key={index} data={prop}
            onClick={() => this.handlePressEvent(prop.id, prop)}
            callback={(id, rating) => this.validateFeedback(id, rating)}
          />
        )
      })}
    </>);
  }

  renderNoServicesMessage = () => {
    return (
      <div className="no-services-message-container align-center justify-content-center m-3 p-3 m-lg-5 p-lg-5">
        <p> {Labels.noServicesMessage(Helper.companyName())}</p>
      </div>
    );
  }

  goToSlide = (slide) => {
    if (this.swiperInstance) {
      this.swiperInstance.slideTo(slide);
    }
  };

  renderServicesList = () => {
    if (!LocalData.terms_accepted && !Helper.isLiteVersion() && !Helper.isDemo()) return null;
    const { progressWorks, scheduleWorks, completedWorks, showcaseWork,
      progressWorksState, scheduleWorksState, completedWorksState, isMobile, swiperLoaded, loadingState: { progress, schedule, completed } } = this.state;
    const isLoading = !Helper.isLiteVersion() ? (progress || schedule || completed) && !LocalData.servicesLoaded : this.state.isLoading && !this.isLiteWithoutToken;
    const hasNoServices = Helper.hasNoServices([progressWorks, scheduleWorks, completedWorks], showcaseWork);
    const countProgressServices = Helper.countProgressServices(progressWorks, showcaseWork);
    const countScheduleServices = Helper.countScheduleServices(scheduleWorks, showcaseWork);
    const countToFeedbackServices = Helper.countToFeedbackServices(completedWorks, showcaseWork);
    
    if (isLoading) {
      return;
    }

    if (hasNoServices) {
      setTimeout(() => {
        return this.renderNoServicesMessage();
      }, 100);
    }
    if (isMobile && !swiperLoaded && !isLoading) {
      setTimeout(() => {
        this.setState({swiperLoaded: true});
        BVService.swiperLoaded = true;
      }, 500);
    }
    
    if (isMobile && swiperLoaded && !isLoading) {
      let initialSlide = !Helper.isLiteVersion()
        ? BVService.getInitialSlide(progressWorks, scheduleWorks, completedWorks)
        : BVService.getInitialLiteSlide(
            progressWorksState,
            scheduleWorksState,
            completedWorksState
          );
      BVService.setSwiperSlide(initialSlide);
      const onSwiper = (swiper) => {
        this.swiperInstance = swiper;
      }

      return (
        <div className="swiper-services-container mt-2" id="swiper-services-container">
          <div className="swiper-tabs-container row m-0" role="button" id="swiper-tabs-services">
            <div className="swiper-tab col-4 pb-2" onClick={() => this.goToSlide(0)}>
              {Labels.swiper.slide1}
              {countProgressServices && countProgressServices > 0 ? <div className="swiper-title-counter"> ({countProgressServices})</div> : null}
              {countProgressServices && countProgressServices > 0 ? <div className="swiper-title-counter-alert" /> : null}
            </div>
            <div className="swiper-tab col-4 pb-2" onClick={() => this.goToSlide(1)}>
              {Labels.swiper.slide2}
              {countScheduleServices && countScheduleServices > 0 ? <div className="swiper-title-counter"> ({countScheduleServices})</div> : null}              
              {countScheduleServices && countScheduleServices > 0 ? <div className="swiper-title-counter-alert" /> : null}
            </div>
            <div className="swiper-tab col-4 pb-2" onClick={() => this.goToSlide(2)}>
              {Labels.swiper.slide3}
              {countToFeedbackServices && countToFeedbackServices > 0 ? <div className="swiper-title-counter"> ({countToFeedbackServices})</div> : null}
              {countToFeedbackServices && countToFeedbackServices > 0 ? <div className="swiper-title-counter-alert" /> : null}
            </div>
          </div>
          <Swiper
            initialSlide={initialSlide}
            slidesPerView={1}
            onSlideChange={(slide) => {
              BVService.setSwiperSlide(slide?.activeIndex);
              this.setState({ currentSlide: slide?.activeIndex });
              const winScroll = this.state.firstTimeSwiperLoading ? BVService.getScrollPos(slide?.activeIndex) : 0;
              this.setState({ firstTimeSwiperLoading: false });
              setTimeout(() => {
                this.scrollTo(winScroll);
              }, 300);
            }}
            onSwiper={onSwiper}
            scrollbar={{
              hide: false,
              draggable: true,
            }}
            modules={[Scrollbar]}
            autoHeight={true}
            touchRatio={1}
            longSwipesRatio={0}
            speed={80}
            resistance={false}
            touchAngle={30}
            zoom={false}
            onSliderMove={() => {
              this.setState({ currentSlide: null });
            }}
            // threshold={1}
            // followFinger={false}
            // freeMode={true}
            // cssMode={false}
          >
            <SwiperSlide>{this.renderProgressList()}</SwiperSlide>
            <SwiperSlide>{this.renderScheduleList()}</SwiperSlide>
            <SwiperSlide>{this.renderHistoryList()}</SwiperSlide>
            {/* ... additional slides */}
          </Swiper>
        </div>
      );
    }

    return isMobile || isLoading ? null : (
      <React.Fragment>
        <Grid title="Em Curso" status={progressWorksState} type="progress" counterAlert={countProgressServices} styleClass="mt-3">
          {this.renderProgressList()}
        </Grid>
        <Grid title="Agendamentos" status={scheduleWorksState} type="schedule" counterAlert={countScheduleServices}>
          {this.renderScheduleList()}
        </Grid> 
        <Grid title="Histórico" status={completedWorksState} type="completed" counterAlert={countToFeedbackServices}>
          {this.renderHistoryList()}
        </Grid>
      </React.Fragment>
    );
  }

  renderMobileTabs = () => {

  };

  renderLoader = () => {
    const { loadingState: { progress, schedule, completed } } = this.state;
    const isLoading = !Helper.isLiteVersion() ? (progress || schedule || completed) && LocalData.terms_accepted : this.state.isLoading;

    if (isLoading && !this.isLiteWithoutToken && !LocalData.servicesLoaded) {
      return (
        <Loader
          message="A carregar..."
          local={true}
          big={true}
          inverted={true}
          height={"250px"}
        ></Loader>
      );
    } else {
      LocalData.servicesLoaded = true;
    }

    return;
  }

  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()}/>
    );
  }

  renderModal = (view, isMedia = false, occupyScreen=false) => {
    this.refModal.current.renderView(view, isMedia, occupyScreen);
    this.refModal.current.openModal();
  }

  /**
   * Render da view
   */
  render() {
    const { isLoading, swiperLoaded, currentSlide, isMobile } = this.state;
    const companyName = Helper.companyName();

    return (
      <>
        <IAgreeBox url={window.location} />
        <div
          className="main-container overflow-hidden-mobile"
          id="main-container"
          key={this.state.rerenderKey}
        >
          <div
            className="list-view-container overflow-hidden-mobile"
            id="scroll-container"
            ref={this.listviewRef}
          >
            {this.renderLoader()}
            {this.renderServicesList()}
          </div>

          {isMobile && swiperLoaded && !isLoading && currentSlide === 1 && (
            <div className="container-main-footer-button">
              {this.renderAddNewAppointmentButton(companyName)}
            </div>
          )}

          <Modal ref={this.refModal} />
          <Helmet>
            {" "}
            <title> {Helper.titleServicesPage()} </title>{" "}
          </Helmet>
        </div>
      </>
    );
  }
}

export default withRouter(Home);


