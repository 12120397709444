// Endpoints for the keymaster API
const GET_CONFIG =                  '/config';
const GET_UPLOAD_CONFIG =           '/config/filetypes';

const USER_SIGN_UP =                '/user/signup/';
const GET_CLIENT_DATA =             '/user/client/';
const AUTHENTICATE_USER =           '/user/login/';
const AUTHENTICATE_PROVIDER_USER =  '/user/login/provider'
const AUTHENTICATE_DEMO_USER =      '/user/login/demo'
const UPDATE_CLIENT_DATA =          '/user/client/';
const DELETE_CLIENT_DATA =          '/user/client/';
const EMAIL_CONFIRMATION =          '/user/email/';
const REGISTER_NEW_CLIENT =         '/user/client/';
const ALTER_USER_PASSWORD =         '/user/password/';
const GET_COMPANIES_COUNT =         '/user/menu/counters';
const RECOVER_PASSWORD =            '/user/password/recover/';
const PRE_REGISTER_NEW_CLIENT =     '/user/client/presignup/';
const DEFINE_CLIENT_NEW_PASSWORD =  '/user/password?token=';
const EMAIL_REGISTER_CONFIRM =      '/user/email/confirm?token=';
const VALIDATE_USER_PHONE =         '/user/phone/validate';
const REQUEST_PHONE_CODE_TO_VALIDATE= '/user/phone';
const VALIDATE_USER_EMAIL =         '/user/email/validate';
const REQUEST_EMAIL_CODE_TO_VALIDATE= '/user/email/code';
const NOTIFICATIONS_LIST =           '/user/notifications/list';
const NOTIFICATIONS_LIST_CHANGES =   '/user/notifications/list/changes';
const NOTIFICATION_READ =           '/user/notifications/{id}/read/';
const NOTIFICATION_READ_ALL =       '/user/notifications/readall/';
const NOTIFICATION_DELETE =         '/user/notifications/{id}/delete/';

const PRE_SIGNUP =                  '/user/client/presignup';

const GET_COMPANIES =               '/client/company';
const GET_NEW_COMPANIES =           '/client/company/new';

const GET_FO_LIST =                 '/services/';
const SCHEDULE_SERVICE =            '/services/';
const GET_FO_DETAILS =              '/services/{id}/';
const ADD_CLIENT_OBS =              '/services/{id}/chat/add';
const EDIT_PERSONAL_OBS =           '/services/{id}/editInternalObs';
const READ_OBSERVATIONS =           '/services/{id}/readCompanyObs';
const READ_SERVICE_CHECK =          '/services/{id}/readServiceReady';
const ADD_CLIENT_MEDIA =            '/services/{id}/media';
const REMOVE_CLIENT_MEDIA =         '/services/{id}/media/{media_id}';
const CANCEL_FO =                   '/services/{id}/cancel';
const SUBMIT_APPROVE_SERVICE =      '/services/{id}/approve';
const SUBMIT_INVOICE =              '/services/{id}/invoice';
const SERVICE_FEEDBACK =            '/services/{id}/feedback/';
const GET_WORKS_INPROGRESS =        '/services/active';
const GET_WORKS_SCHEDULE =          '/services/scheduled';
const GET_WORKS_COMPLETED =         '/services/completed';

const GET_ITEM_LIST =               '/items/';
const ADD_ITEM_TO_USER =            '/items/';
const GET_ITEM_INFO =               '/items/{id}/';
const ALTER_ITEM_DETAIL =           '/items/{id}/';
const REMOVE_ITEM_FROM_USER =       '/items/{id}/';
const RESET_ITEM_DETAILS =          '/items/{id}/reset/';
const CHANGE_ITEM_IMAGE =           '/items/{id}/images/';
const GET_ITEM_WORKS_INPROGRESS =   '/items/{id}/works/active/';
const GET_ITEM_WORKS_SCHEDULE =     '/items/{id}/works/scheduled/';
const GET_ITEM_WORKS_COMPLETED =    '/items/{id}/works/completed/';

const GET_SLOTS =                   '/slots';
//const GET_TERMS_SHOP =              '/terms/';
const GET_TERMS_SHOP =              '/terms/company';
const GET_SERVICES_SYMPTOMS =       '/symptoms/';
const APP_FEEDBACK =                '/application/feedback/';
const SUPORT_REQUEST =              '/application/support/';
const GET_AVAILABLE_ESTABLISHMENT = '/establishment';
const ESTABLISHMENT_FEEDBACK =      '/establishment/{id}/feedback/';
const ESTABLISHMENT_DISTANCE =      '/establishment/{id}/distance/';
const ALL_ESTABLISHMENT_DISTANCE =  '/establishment/distance/';

const GET_COUNTRIES_DATA =          '/countries';
const GET_CLUSTER_COOKIE =          '/mgmt/route';
const GET_SHOWCASE_WORK =           '/showcase/work/{id}'; // ?debug_code=3'
const GET_SHOWCASE_WORK_CAL =       '/customer/showcase/{id}';
const POST_SHOWCASE_CAL_CONFIRM =   '/customer/showcase/{id}/confirm';
const PUSH_EVENT_ANALYTICS =        '/analytics?key=click&value=keymaster';
const PUSH_WORK_ANALYTICS =         '/analytics/work?id={id}&s={source}&t={type}&r={registered}';

const LOG_LITE =                    '/log';

const setPathVariable = (endpoint, pathVarId, pathVarValue, pathVarId2 = null, pathVarValue2 = null, pathVarId3 = null, pathVarValue3 = null, pathVarId4 = null, pathVarValue4 = null, pathVarId5 = null, pathVarValue5 = null) => {
  const targetId = `{${pathVarId}}`;
  const targetId2 = pathVarId2 ? `{${pathVarId2}}` : null;
  const targetId3 = pathVarId3 ? `{${pathVarId3}}` : null;
  const targetId4 = pathVarId4 ? `{${pathVarId4}}` : null;
  const targetId5 = pathVarId5 ? `{${pathVarId5}}` : null;
  // Replace the placeholders with the provided values
  let res = endpoint.replace(targetId, pathVarValue);
  if (targetId2) { res = res.replace(targetId2, pathVarValue2); }
  if (targetId3) { res = res.replace(targetId3, pathVarValue3); }
  if (targetId4) { res = res.replace(targetId4, pathVarValue4); }
  if (targetId5) { res = res.replace(targetId5, pathVarValue5); }
  return res;
}

module.exports = {
  setPathVariable,
  GET_CONFIG,
  GET_SERVICES_SYMPTOMS,
  GET_SLOTS,
  DEFINE_CLIENT_NEW_PASSWORD,
  REGISTER_NEW_CLIENT,
  EMAIL_CONFIRMATION,
  AUTHENTICATE_USER,
  AUTHENTICATE_PROVIDER_USER,
  AUTHENTICATE_DEMO_USER,
  RECOVER_PASSWORD,
  USER_SIGN_UP,
  SERVICE_FEEDBACK,
  GET_CLIENT_DATA,
  UPDATE_CLIENT_DATA,
  ALTER_USER_PASSWORD,
  GET_ITEM_LIST,
  ADD_ITEM_TO_USER,
  GET_FO_LIST,
  CANCEL_FO,
  GET_FO_DETAILS,
  SCHEDULE_SERVICE,
  GET_ITEM_INFO,
  CHANGE_ITEM_IMAGE,
  PRE_REGISTER_NEW_CLIENT,
  EMAIL_REGISTER_CONFIRM,
  REMOVE_ITEM_FROM_USER,
  GET_AVAILABLE_ESTABLISHMENT,
  GET_TERMS_SHOP,
  ESTABLISHMENT_FEEDBACK,
  APP_FEEDBACK,
  ESTABLISHMENT_DISTANCE,
  ALL_ESTABLISHMENT_DISTANCE,
  NOTIFICATIONS_LIST,
  NOTIFICATIONS_LIST_CHANGES,
  NOTIFICATION_READ,
  NOTIFICATION_READ_ALL,
  NOTIFICATION_DELETE,
  GET_WORKS_SCHEDULE,
  GET_WORKS_INPROGRESS,
  GET_WORKS_COMPLETED,
  SUPORT_REQUEST,
  ALTER_ITEM_DETAIL,
  GET_ITEM_WORKS_SCHEDULE,
  GET_ITEM_WORKS_INPROGRESS,
  GET_ITEM_WORKS_COMPLETED,
  RESET_ITEM_DETAILS,
  GET_COUNTRIES_DATA,
  SUBMIT_INVOICE,
  GET_SHOWCASE_WORK,
  GET_SHOWCASE_WORK_CAL,
  POST_SHOWCASE_CAL_CONFIRM,
  SUBMIT_APPROVE_SERVICE,
  GET_CLUSTER_COOKIE,
  GET_COMPANIES,
  GET_NEW_COMPANIES,
  ADD_CLIENT_OBS,
  ADD_CLIENT_MEDIA,
  PUSH_EVENT_ANALYTICS,
  REMOVE_CLIENT_MEDIA,
  GET_UPLOAD_CONFIG,
  GET_COMPANIES_COUNT,
  READ_OBSERVATIONS,
  READ_SERVICE_CHECK,
  LOG_LITE,
  EDIT_PERSONAL_OBS,
  DELETE_CLIENT_DATA,
  PRE_SIGNUP,
  VALIDATE_USER_PHONE,
  REQUEST_PHONE_CODE_TO_VALIDATE,
  VALIDATE_USER_EMAIL,
  REQUEST_EMAIL_CODE_TO_VALIDATE,
  PUSH_WORK_ANALYTICS
};
