// React Imports
import React  from 'react';
// Components
import Modal, { OpenMedia, Information } from 'components/modal/modal.jsx';
import Labels from '../../variables/labels.js';
// Styles
import 'components/gallery/gallery.style.scss';
import GalleryImageCard from './galleryImageCard';
import Helper from '../../core/helper/helper.js';
import { SERVICE } from '../../assets/images/images.js';


class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.refModal = React.createRef();
    this.refInputFile = React.createRef();
    this.state = this.getInitialStateFromProps(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState(this.getInitialStateFromProps(this.props));
      if (this.state.actionCloseGallery) {this.refModal.current.closeModal();}
    }
  }

  getInitialStateFromProps = (props) => {
    const { media, serviceState} = props.data;
    const {onCreateAppointment, actionCloseGallery} = props;
    return {
      actionCloseGallery,
      onCreateAppointment: onCreateAppointment,
      title: props.title,
      spans: 0,
      data: media,
      serviceState,
    };
  };

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  hasData(data) {
    return data && !this.isEmpty(data);
  }

  /**
  * Apresentação do modo preview da imagem/video selecionada pelo o utilizador
  *
  * @param {string} value - url da imagem/video
  */
   handlePressedPhoto = (item, index, type) => {
    const { data, serviceState } = this.state;
    if (!Helper.isLiteVersion() || this.state.onCreateAppointment) {
      const url = Helper.urlFullWorkImage(item.url);
      this.renderModal(
        <OpenMedia
          src={url}
          item={item}
          items={data[type]}
          index={index}
          hasDeleteIcon={Helper.showUploadButton(serviceState, type)}
          onDeleteMediaFile={(mediaId) => this.props.onDeleteMediaFile(mediaId)}
        />,
        true
      );
    } else {
      this.renderModal(
        <Information
          title={Labels.liteSeeMediaTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => (Helper.logoutFromLite())}
        />
      );
    }
  }

  handleUploadClick = () => {
    if (!Helper.isLiteVersion() || this.state.onCreateAppointment) {
      this.refInputFile.current.click();
    } else {
      this.renderModal(
        <Information
          title={Labels.liteUploadMediaTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => (Helper.logoutFromLite())}
        />
      );
    }
  }

  handleUpdateMedia = async (event) => {
    event.preventDefault();
    let thumbnail = null;
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file.type.includes('video')) {
      thumbnail =  await Helper.generateVideoThumbnail(file);
    } else {
      thumbnail = await Helper.generateImageThumbnail(file)
    }

    reader.onloadend = () => {
      this.props.newMediaFileLoaded(file, thumbnail || null);
    };
    reader.readAsDataURL(file);
  }

  renderUploadButton = (type) => {
    const { serviceState } = this.state;
    if (Helper.hideUploadButton(serviceState, type)) {
      return;
    }

    return (
      <div className="upload-button-container ms-2 mb-1" onClick={() => this.handleUploadClick()}>
        <label htmlFor="upload-photo" className='d-flex align-items-center lh-sm'>
          <img src={SERVICE.maquina_foto_std} className="icon-button me-1" alt=""  role={'button'}/>
          {Labels.button.uploadMediaButton}
        </label>
        <input ref={this.refInputFile}
          type="file" accept={Helper.mediaFileAccept}
          onChange={this.handleUpdateMedia} />
      </div>
    );
  }

  /**
  * Render da galeria de fotografias
  *
  * @param {any} data - objecto com as fotografias
  */
  renderGallery = (type) => {
    const { data } = this.state;
    const renderContent = () => {
      if (!this.hasData(data[type])) {
        return (
          <div className='d-column-flex px-3 py-3'>
            <div className='d-flex justify-content-center border-mask'>
              <div>{ Labels.emptyGalleryMessage }</div>
            </div>
          </div>
        );
      }
      return (
        <div className='gallery-photos-container'>
          <div className='gallery-photos-scroll-view'>
            {this.renderImages(data[type], type)}
          </div>
        </div>
      );

    }
    const subtitleClass = type === 'customer' ? 'normal-semibold-black' : 'mt-2 normal-semibold-black';
    return (
      <React.Fragment>
        <div className="w-100 row-list-title-container px-3 pt-3 d-inline-flex justify-content-between">
          <div className={subtitleClass}>
            {type === 'customer' ? Labels.galleryByCustomer : Labels.galleryByProvider}
          </div>
          {this.renderUploadButton(type)}
        </div>
        {renderContent()}
      </React.Fragment>
    );
  }

  /**
  * Render do elemento de cada fotografia dentro da galareia
  *
  * @param {any} media - fotografias a apresentar na galeira
  */
  renderImages = (media, type) => {
    if (media && media !== "") {
      return media.map((item, index) => {
        return (<GalleryImageCard onCreateAppointment={this.state.onCreateAppointment} item={item} key={index} onClick={() => this.handlePressedPhoto(item, index, type)}/>);
      });
    }
  }

  render() {
    return (
      <div className="row-list-container tab-left container-kss gallery open mt-0 mb-0 pt-0">
        {!this.state.onCreateAppointment && this.renderGallery('provider')}
        {this.renderGallery('customer')}
        <Modal ref={this.refModal} />
      </div>
    );
  }
  renderGenericError = () => {
    this.renderInfoModal(Labels.genericErrorTitle, Labels.genericErrorMessage);
  }

  renderInfoModal = (title, message) => {
    this.renderModal(
      <Information
        title={title}
        text={message}
        onClick={() => this.refModal.current.closeModal()} />
    );
  }

  renderModal = (view, param = null) => {
    this.refModal.current.renderView(view, param);
    this.refModal.current.openModal();
  }
}

export default Gallery
