// React Imports
import React from 'react';
import Emitter from 'core/services.js';
// Style
import 'components/list/grid/grid.style.scss';
// Assets
import { GENERAL } from 'assets/images/images.js';
import CommentBox from '../../card/CommentBox/CommentBox';
import Labels from '../../../variables/labels';
import Helper from '../../../core/helper/helper';
import ObsChat from '../../card/ObsChat/ObsChat';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: this.props.status,
    };
  }

  componentDidMount() {
    Emitter.on('LIST_GRID_STATE_CHANGED', (type) => this.forceToggle(type));
    Emitter.on('OBSERVATIONS_SHINING', () => {
      if(this.props.obsType === "message" && this.props.hasObservationItem)
        this.setState({shown: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;
   
    if (status !== prevProps.status) {
      this.setState({shown: status})
    }
  }


  componentWillUnmount() {
    Emitter.off('LIST_GRID_STATE_CHANGED');
    Emitter.off('OBSERVATIONS_SHINING');
  }

  forceToggle(type) {
    if (type === this.props.type) {
      this.toggle();
    }
  }

  saveGridStatus(state) {
    sessionStorage.setItem(`${this.props.type}GridState`, state);
  }

  toggle = ()=> {
    const { counterAlert, obsType } = this.props;
    
    if (!this.state.shown && obsType === "message" && counterAlert > 0 && typeof this.props.onReadMessage === 'function') {
        this.props.onReadMessage();
    }

    if (this.props.type) {
      this.saveGridStatus(!this.state.shown);
    }
    this.setState({
      shown: !this.state.shown,
    });
  }

  renderGridContainer = () => {
    const { obsType, hasObservationItem } = this.props;
    const { shown } = this.state;

    return (
      <div className={`${shown ? 'grid-container' : 'grid-container-closed'} 
                      ${hasObservationItem ? 'observation pt-0' : ''} 
                      ${obsType === 'message' ? 'chat' : ''}`}
        style={{ justifyContent: this.props.center ? 'center' : '' }}>
				<div className="children-container w-100">
					{shown && this.props.children}
          {obsType === "message" && hasObservationItem ? (
            <ObsChat {...this.props} gridOpen={shown} openGrid={()=>this.setState({shown: true})}/>
          ) : (
            <>
              {this.renderObservations()}
            </>
          )}
				</div>
			</div>
		);
	};

  renderObservations = () => {
    const { obsData, obsType, onSaveObservation } = this.props;
    if (!this.props.hasObservationItem) {
      return;
    }

    return (
      <CommentBox
        saveObservation={onSaveObservation}
        observations={obsData.observations}
        obsType={obsType}
        readOnly={obsData.readOnly}
        noClickAction={obsData.noClickAction}
      />
    );
  }

  renderIcon = () => {
    const { icon/*, hasObservationItem, hasSymptoms */} = this.props;
    if (!icon) { return; }
    //let renderIcon = hasObservationItem && hasSymptoms && !this.state.shown ? GENERAL.warning : icon;
    let renderIcon = icon;
    return <img className="d-none d-lg-block icon me-2" src={renderIcon} alt="" />
  }

  renderToggleButton() {
    return <img className="open-grid-button my-0" role={'button'} src={this.state.shown ? GENERAL.iconSmallArrowUp : GENERAL.iconSmallArrowDown} alt="" />;
  }

  renderCounterAlert() {
    if (this.props.obsType === "message" && this.props.counterAlert && this.props.counterAlert > 0) {
      return (
        <div className="message-counter-container">
          <img src={GENERAL.messageCounter} alt="Counter Icon" className="message-counter-icon" />
          <div className="message-counter-number">
            {this.props.counterAlert}
          </div>
        </div>
      );
    }
    if (this.props.obsType !== "message") {
      return this.props.counterAlert && this.props.counterAlert > 0 ? <div className="row-list-title-counter-alert" /> : null;
    }
  }

	renderAlertLabel() {
    const companyName = Helper.companyName();
		const { counterAlert, type } = this.props;
		let message = null;
    const pluralSuffix = counterAlert > 1 ? 's' : '';
    const completedLabel = Labels.counterMessages.classification
                          .replace(Labels.counterMessages.companyname, companyName)
                          .replace(/{{plural}}/g, pluralSuffix);


		switch (type) {
        case "completed":
        message = counterAlert && counterAlert > 0
        ? <div className="row-list-counter-message">{completedLabel} </div> 
        : null
				break;
			default:
				break;
		}
		return message;
	}


  render() {
    const { styleClass, id, obsType, counterAlert } = this.props;
    const { shown } = this.state;
    const showAlert = obsType !== "message" && counterAlert > 0 ;

    return (
      <div className={`row-list-container container-kss  ${shown ? 'open' : ''} ${styleClass || ''}`} id={id}>
        <div className={`row-list-title-container flex-between pe-4 py-2 py-md-3 ps-3 ps-md-4 ${obsType === 'message' ? 'chat' : ''}`}
          onClick={() => this.toggle()}>
          <div className='d-flex align-items-end'>
            {this.renderIcon()}
            <span>{this.props.title}</span> 
            {showAlert && <div className="swiper-title-counter"> ({counterAlert})</div>}
            {this.renderCounterAlert()}
            {this.renderAlertLabel()}
          </div>
          {this.renderToggleButton()}
        </div>
        { this.renderGridContainer() }
      </div>
    );
  }
}

export default Grid;
