import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import 'components/button/button.style.scss';

class Button extends Component {

  _handlePath = (path, value) => {
    if (value) {
      this.props.history.push({
        pathname: path,
        state: { email: value }
      });
    } else {
      this.props.history.push(path);
    }
  }

  render() {
    if (this.props.type === 'submit' && !this.props.nextRow) {
      return (
        <div className='button-main-container'>
          <input className={this.props.className} type={this.props.type} value={this.props.value}
            onClick={this.props.onClick} style={this.props.style} />
        </div>
      );
    }
    if (this.props.type === 'submit' && this.props.nextRow) {      
      return (
        <div className='button-main-container next-row'>
          <input className={this.props.className} type={this.props.type} value={this.props.value} onClick={this.props.onClick} />
        </div>
      );
    }
    if (this.props.type === 'button') {
      const classNameContainer = this.props.className.includes('hidden') ? 'hidden' : 'button-main-container';
      return (
        <div className={classNameContainer}>
          <button className={this.props.className} type={this.props.type} onClick={this.props.onClick}>{this.props.value}</button>
        </div>
      );
    }
    if (this.props.type === 'iconAndText') {
      const loading = this.props.loading;
      let classNameContainer = this.props.className.includes('hidden') ? 'hidden' : 'button-main-container mt-2 mb-1';
      if (loading) classNameContainer += ' disabled';

      return (
        <div className={classNameContainer}>
          <button
            className={this.props.className}
            type={this.props.type}
            onClick={this.props.onClick}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {loading ?
              <div
                className='spinner'
                style={{ margin: '0 8px 0 0' }}
              /> :
              !this.props.icon ? null : <img
                className='icon-tint-dynamic'
                src={this.props.icon}
                alt=""
                style={{ margin: '0 8px 0 0' }}
              />
            }
            {this.props.value}
          </button>
        </div>
      );
    }
    if (this.props.type === 'smallSideButton')
      return (
        <div className='inside-button-container' onClick={this.props.onClick}>
          <img className='icon-container' src={this.props.icon} alt="" />
        </div>
      );
    if (this.props.type === 'addSideButton') {
      return (
        <div className='inside-button-container mb-2' onClick={this.props.onClick}>
          <img className='icon-container' src={this.props.icon} alt="" />
        </div>
      );
    }

    if (this.props.type === 'link')
      return (
        <div className={this.props.className} onClick={this.props.onClick}>
          <p>
            <span className={`link-container-span ${this.props.textClass ? this.props.textClass : ''}`}>
              {this.props.valueText}
            </span>
            {this.props.valueLink}
          </p>
        </div >
      );
    if (this.props.type === 'linkNav') {

      if (this.props.email) {
        return (
          <div onClick={() => this._handlePath(this.props.to, this.props.email)} className={this.props.className}>
            <p>{this.props.value}</p>
          </div>
        );
      } 
      return (
        <div onClick={() => this._handlePath(this.props.to, null)} className={this.props.className}>
          <p>{this.props.value}</p>
        </div>
      );
    }
    if (this.props.type === 'icon') {
      return (
        <div className={this.props.className} onClick={this.props.onClick}>
          {this.props.icon}
        </div>
      );
    }
    if (this.props.type === 'showPassword') {
      const { className } = this.props;
      return (
        <div className={'inside-button-container'} onClick={this.props.onClick}>
          <img className={`icon-container ${className}`} src={this.props.icon} alt="eyeIcon" />
        </div>
      );
    }
    if (this.props.type === 'approveButton') {
      const classNameContainer = this.props.className.includes('hidden') ? 'hidden' : 'button-main-container';
      return (
        <div className={classNameContainer}>
          <button className={this.props.className} onClick={this.props.onClick}>{this.props.value}</button>
        </div>
      );
    }
  }
}

export default withRouter(Button);



