// React Imports
import React from "react";
// Styles
import "components/card/ObsChat/ObsChat.style.scss";
import Helper from "../../../core/helper/helper";
import Emitter from "../../../core/services";
import Labels from "../../../variables/labels";
import LocalData from "../../../core/localData";
import Modal, { Information } from "../../modal/modal";
import Loader from "../../loader/loader";
import { GENERAL } from "../../../assets/images/images";
import classNames from "classnames"

const cLabels = {
	internalMessageTitle:
		"Deseja enviar e receber comentários/observações sobre o serviço?",
	liteSendMessagePlaceholder: "Inicie sessão para enviar comentários e falar com o seu prestador.",
	sendObsPlaceholder: "Comentários/observações",
	sendMessagePlaceholder: "Fale com o seu prestador",
};

class ObsChat extends React.Component {
	constructor(props) {
		super(props);
		this.refInput = React.createRef();
		this.refModal = React.createRef();
		this.observationsContainerRef = React.createRef();
		this.historyRef = React.createRef();

		// Logic to determine if there are unread company messages
		const chat = Array.isArray(props.obsData?.history) && props.obsData.history.length > 0;
		const unreadCounter = chat ? props.obsData.history.filter(
			(message) => message.type === 'COMPANY' && !message.read_date
		  ).length : 0;

		this.state = {
			mobileKeyboardVisible: false,
			shining: false,
			open: false,
			readOnly: props.obsData.readOnly,
			noClickAction: props.obsData.noClickAction,
			showSendButton: false,
			isLoading: false,
			isBoxShining: false,
			isListTop: true,
			isListBottom: true,
			unreadCounter: unreadCounter,
			gridOpen: props.gridOpen,
		};
	}

	componentDidMount() {
		Emitter.on('MOBILE_KEYBOARD_VISIBLE', (value) => {
			if (window.innerWidth <= 768 && (Helper.isAndroid() || Helper.isIOS())) {
			  this.setState({ mobileKeyboardVisible: value });
			}
		  });
		Emitter.on("OBSERVATIONS_SHINING", () => {
			this.focusOnObservationsBox(true);
		});
		Emitter.on("OBS_MESSAGE_SENT_LOADING", (data) => {
			if (data === 'success') {
				this.setState({
					isLoading: false,
				});
				if (this.refInput && this.refInput.current) {
					this.refInput.current.value = "";
				}
			} else if (data === 'error') {
				this.setState({
					isLoading: false,
					showSendButton: true,
				});
			}
		});

		this.scrollToBottom();

		document.addEventListener("mousedown", this.handleClickOutside);
		if (this.historyRef.current) {
			this.historyRef.current.addEventListener("scroll", this.listenToListScroll);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { open, gridOpen } = this.state;

		if (this.props.gridOpen !== gridOpen) {
			this.setState({ gridOpen: this.props.gridOpen });
		}

		// Scroll to bottom on certain state or prop changes
		if ((prevState.open && !open) || (prevProps.obsData?.history !== this.props.obsData?.history) || (!prevState.gridOpen && gridOpen)) {
		  this.scrollToBottom();
		}
	
		if (prevProps.obsData?.history !== this.props.obsData?.history) {
			const chat = Array.isArray(this.props.obsData?.history) && this.props.obsData.history.length > 0;
			const unreadCounter = chat ? this.props.obsData.history.filter(
			  (message) => message.type === 'COMPANY' && !message.read_date
			).length : 0;

			if (unreadCounter !== this.state.unreadCounter) {
			  this.setState({ unreadCounter });
			}
		  }
	  }

	componentWillUnmount() {
		Emitter.emit("MOBILE_KEYBOARD_VISIBLE", false);
		Emitter.emit("MOBILE_KEYBOARD_VISIBLE_SIDEBAR", false);
		Emitter.off("OBSERVATIONS_SHINING");
		Emitter.off("OBS_MESSAGE_SENT_LOADING");

		document.removeEventListener("mousedown", this.handleClickOutside);
		if (this.historyRef.current) {
			this.historyRef.current.removeEventListener('scroll', this.listenToListScroll);
		}
		Emitter.off("MOBILE_KEYBOARD_VISIBLE");
	}


	scrollToBottom = (smooth = false) => {
		if (this.historyRef.current) {
			this.historyRef.current.scrollTo({ top: this.historyRef.current.scrollHeight, behavior: smooth ? "smooth" : "auto"});
		}
	};

	listenToListScroll = (event) => {
		const scrollTop = event.target.scrollTop; // pos do topo do scroll bar
		const containerHeight = event.target.scrollHeight; // Altura total do conteúdo
		const scrollbarHeight = event.target.clientHeight; // Comprimento do scroll bar
		if (scrollTop) {
				this.setState({
					isListTop: scrollTop <= 50,
					isListBottom: containerHeight - scrollTop - scrollbarHeight < 20
				});
		}
	}

  	markMessageAsRead = (shine = false, scrollDown = false) => {
		if (this.state.unreadCounter > 0) {
			this.props.onReadMessage();
			this.setState({ unreadCounter: 0 });
		}
		
		this.focusOnObservationsBox(shine, scrollDown);
	}

	focusOnObservationsBox = (shine = false, scrollDown = false) => {
		const { gridOpen } = this.state;
		const timeout = gridOpen ? 0 : 100;
		if (!gridOpen) this.props.openGrid();

		setTimeout(() => {
			const el = document.getElementById("observations-container");
			// const main = document.getElementById('main-details-container')

			if (this.props.obsData?.history?.length > 2) {
				if (el) {
					const elTop = el.getBoundingClientRect().top;
					const currentScroll = window.scrollY;
					const offset = currentScroll + elTop;

					if (window.innerWidth <= 768) {
						setTimeout(() => {
							window.scrollTo({ top: offset, behavior: "smooth" });
						}, 100);
					}
				}
			}

			this.setState({ open: true });

			if (shine) {
				setTimeout(() => {
					this.setState({ shining: true });
					LocalData.messageIconClicked = false;
					setTimeout(() => {
						this.setState({ shining: false });
					}, 1000);
				}, 500);
			}

			if (scrollDown) {
				setTimeout(() => {
					this.scrollToBottom(true);
				}, 500);
			}
		}, timeout);
	};

	onFocusObservation = (e, readOnly) => {
		if (!this.state.noClickAction && readOnly) {
			this.renderModalLiteMode();
			e.preventDefault();
			return;
		}

		this.setState({ open: true });
		this.markMessageAsRead(false,true);
	};

	handleClickOutside = (event) => {
		const { observationsContainerRef } = this;

		if (
			observationsContainerRef.current &&
			!observationsContainerRef.current.contains(event.target)
		) {
			if (this.state.open) {
				this.setState({ open: false });
			}
		}
	};

	handleObsChange = () => {
		this.setState({
			count: this.refInput.current?.value?.length,
			showSendButton: this.refInput.current.value.length > 0,
		});
	};
	handleObsSave = () => {
		this.refInput.current.focus();
		if (this.refInput.current.value.length > 0) {
			this.props.onSaveObservation(this.refInput.current.value);
			this.setState({
				showSendButton: false,
				isLoading: true,
			});
		}
	};
	renderSendButton = () => {
		const { obsData } = this.props;
		const history = obsData?.history?.length > 0;
		const { showSendButton, isLoading, gridOpen } = this.state;

		if (isLoading) {
			return (
				<div className={`inside-textarea-button-container ${history ? 'chat' : ''} loading`}>
					<Loader
						message=""
						inverted={true}
						local={true}
						big={false}
						small={true}
					/>
				</div>
			);
		}

		return (
			<div
				className={`
					${history ? 'chat' : ''}  
					${showSendButton ? 'background-color-blue-dark' : 'background-color-transparent'}
					${!gridOpen? 'grid-closed' : ''}
					inside-textarea-button-container 
					`}
				style={{
					boxShadow: showSendButton ? '1px 0 8px 0 #000' : 'none',
					padding: '16px',
					}}
				role={"button"}
				onClick={() => {
					if (!showSendButton && this.refInput?.current) {
					  this.refInput.current.focus();
					} else {
					  this.handleObsSave();
					}
				  }}
			>
				<img
					src={GENERAL.send}
					className={`
						${showSendButton ? 'filter-white' : 'filter-blue-light'} 
						`}
					style={{ height: "22px" }}
					alt="send-icon"
				/>
			</div>
		);
	};
	renderModalLiteMode = () => {
		this.renderModal(
			<Information
				title={cLabels.internalMessageTitle}
				//text={Labels.liteGenericMessage}
				confirmText={Helper.getLiteRegisterButton()}
				onClick={() => Helper.logoutFromLite()}
			/>
		);
	};

	renderModal = (view) => {
		this.refModal.current.renderView(view);
		this.refModal.current.openModal();
	};

	getPlaceholderText(chat) {
		if (Helper.isLiteVersion()) {
			return cLabels.liteSendMessagePlaceholder;
		} else {
			return cLabels.sendMessagePlaceholder;
		}
	}

	render() {
		const { obsData } = this.props;
		const { comment, readOnly, isListTop, isListBottom, mobileKeyboardVisible, gridOpen } = this.state;
		const history = obsData?.history;
		const chat = Array.isArray(history) && obsData?.history?.length > 0;

		let renderBorderClass = chat ? "border-1-blue" : "";

		return (
			<div
				id="observations-container"
				className={`obs-history-container 
					${this.state.shining ? "shining" : ""} 
					${mobileKeyboardVisible ? 'mobileKeyboardVisible' : ''}
					${gridOpen ? '' : 'grid-closed'}
					`}
				ref={this.observationsContainerRef}
			>
				{gridOpen && (
					<div className="title">
						{Labels.servicesClientCommentLabel}
					</div>
				)}
				{gridOpen && history && chat && (
					<div
						className={`history ${this.state.open ? "open" : ""} ${mobileKeyboardVisible ? 'mobileKeyboardVisible' : ''}`}
						ref={this.historyRef}
						onClick={() => { this.markMessageAsRead() }}
					>
						<div className="box-shadow" style={{display: isListTop ? "none" : "block"}}></div>
						<div className="comments-container p-2">
							{history.map((obs, idx) => (
								<div
									key={idx}
									className={classNames("chat-comment", {
										"client": obs.type === "CLIENT",
										"mt-4": idx > 0 && obs.type !== history[idx - 1]?.type
									})}
								>
									<div
										className={`comment-header px-3 ${obs.type === "CLIENT" ? "client" : ""}`}
									>
										<span className={`header-sender`}>
											{obs.type === "CLIENT"
												? "Eu"
												: Helper.companyName()}
										</span>
										<span className="header-date">
											{obs.date}
										</span>
									</div>
									<div className="comment-body px-2">
										<span
											dangerouslySetInnerHTML={{
												__html: Helper.createLinksInText(obs.message),
											}}
										></span>
									</div>
								</div>
							))}
						</div>
						<div className="box-shadow bottom" style={{ display: isListBottom ? "none" : "block" }}></div>
					</div>
				)}
				<div className={`obs-container mt-2 mt-md-0 w-100 h-100 flex-column ${gridOpen ? '' : 'grid-closed'}`}>
					<form onSubmit={this.handleObsSave} className="w-100">
						<textarea
							ref={this.refInput}
							defaultValue={comment}
							maxLength="1000"
							onChange={this.handleObsChange}
							rows={chat ? 2 : 3}
							className={`obs-container-text-area w-100  ${chat ? "chat" : ""} ${gridOpen ? `${renderBorderClass}` : 'grid-closed'}`}
							placeholder={this.getPlaceholderText(chat)}
							readOnly={readOnly}
							onFocus={(e) => {
								this.onFocusObservation(e, readOnly);
								Emitter.emit("MOBILE_KEYBOARD_VISIBLE", true);
								Emitter.emit("MOBILE_KEYBOARD_VISIBLE_SIDEBAR", true);
							}}
							onBlur={(e) => {
								e.preventDefault();
								setTimeout(() => {
									if (document.activeElement !== this.refInput.current) { // when pressing the send button, onBlur is called but then the field is focussed automatically. When focussed the don't want to call the Emitters.
										Emitter.emit("MOBILE_KEYBOARD_VISIBLE", false);
										Emitter.emit("MOBILE_KEYBOARD_VISIBLE_SIDEBAR", false);
									}
								}, 500); // 0.5 seconds delay to allow the field to be refocused
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter" && !e.shiftKey) {
									e.preventDefault(); // Impede que uma nova linha seja criada
									this.handleObsSave(); // Envia o formulário
								}
							}}
						/>
						{this.renderSendButton()}
					</form>
					<Modal ref={this.refModal} />
				</div>
			</div>
		);
	}
}

export default ObsChat;
