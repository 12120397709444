// React Imports
import axios from 'axios';
import fileDownload from 'js-file-download';
import React from 'react';
import { GENERAL } from '../../assets/images/images';
import Helper from '../../core/helper/helper';
import Labels from '../../variables/labels';
import Modal, { Information } from 'components/modal/modal.jsx';
import Cookies from 'universal-cookie';

class DocumentList extends React.Component {

  constructor(props) {
    super(props);
    const { data, onCreateAppointment } = this.props;

    this.cookies = new Cookies();
    this.refInputFile = React.createRef();
    this.refModal = React.createRef();

    const hasDocDate = {
      customer: Helper.listyHasPropValue(data.documents, 'date', 'customer'),
      provider: Helper.listyHasPropValue(data.documents, 'date', 'provider'),
    };
    
    this.state = {
      onCreateAppointment: onCreateAppointment,
      data: data.documents,
      serviceState: data.serviceState,
      customer: {
        hasDocDate: hasDocDate.customer,
        descriptionWidth: hasDocDate.customer ? 'col-6 col-sm-9 col-md-5 col-lg-7 col-xl-5' : 'col-6 col-sm-7',
      },
      provider: {
        hasDocDate: hasDocDate.provider,
        //descriptionWidth: hasDocDate.provider ? 'col-8 col-sm-9 col-md-5 col-lg-9 col-xl-6' : 'col-7 col-sm-9',
        descriptionWidth: hasDocDate.provider ? 'col-6 col-sm-9 col-md-5 col-lg-7 col-xl-5' : 'col-6 col-sm-7',
      }
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the data prop has changed
    if (prevProps.data !== this.props.data) {
      const { data, onCreateAppointment } = this.props;

      const hasDocDate = {
        customer: Helper.listyHasPropValue(data.documents, 'date', 'customer'),
        provider: Helper.listyHasPropValue(data.documents, 'date', 'provider'),
      };

      this.setState({
        onCreateAppointment: onCreateAppointment,
        data: data.documents,
        serviceState: data.serviceState,
        customer: {
          hasDocDate: hasDocDate.customer,
          descriptionWidth: hasDocDate.customer ? 'col-6 col-sm-9 col-md-5 col-lg-7 col-xl-5' : 'col-6 col-sm-7',
        },
        provider: {
          hasDocDate: hasDocDate.provider,
          descriptionWidth: hasDocDate.provider ? 'col-6 col-sm-9 col-md-5 col-lg-7 col-xl-5' : 'col-6 col-sm-7',
        }
      });
    }
  }

  renderDownloadButton = () => {
    return (
      <div className="link-container d-flex h-100" role="button">
        <img src={GENERAL.downloadBlue} alt="icon-link" className="me-1" />
        <span className='d-sm-block text-nowrap'>Download</span>
      </div>
    );
  }

  renderDeleteButton = (type, id) => {
    const { serviceState } = this.state;
    if (!Helper.showUploadButton(serviceState, type)) {
      return;
    }
    const deleteItem = () => {
      this.props.onDeleteMediaFile(id);
    }

    return (
      <div className="doc-row-text col-1 d-flex justify-content-center">
        <div onClick={deleteItem}>
          <img src={GENERAL.delete} className="icon-file-remove" alt="" />
        </div>
      </div>
    );
  }

  renderItems = (data, type) => {
    return data.map((item, index) => {
      const itemId = item.url;
      const url = Helper.urlFullWorkImage(item.url);
      const originalDescription = item?.description ? item.description : '-';
      const date = item?.date || '-';

      const random =  Helper.newRandomString(
        Helper.printValue(originalDescription)?.length - Helper.printValue(originalDescription)?.slice(" ")[1]?.length
      );

      const description = () => {
        if (!Helper.isLiteVersion() || this.props.onCreateAppointment) return originalDescription;
        if (originalDescription.includes(" ") || originalDescription.includes("-")) {
          return originalDescription.split(" ")[0].split("-")[0];
        } else {
          return originalDescription.slice(0, 2);
        }
      }

      const renderDate = (isMobile = false) => {
        if (!this.state[type].hasDocDate) {
          return;
        }

        if (!isMobile) {
          return <div className="doc-row-text col-4 d-none d-md-flex d-lg-none d-xl-flex"> {date} </div>;
        }

        return date;
      }
      return (
        <li className="document-row-container d-flex align-items-center w-100" key={index}>
          <div className={`doc-row-text ps-0 flex-start flex-column ${this.state[type].descriptionWidth}`} id={`doc${index}`}>
            <span className="w-100 text-break">
              {description()}
              {(Helper.isLiteVersion() && !this.props.onCreateAppointment) && <span className='pixelized'>{random}</span>}
            </span>
            <span className='d-md-none d-lg-flex d-xl-none w-100'> {renderDate(true)} </span>
          </div>
          {renderDate()}
          <div className="doc-row-text col d-flex" onClick={() => this.handleDownload(url, originalDescription, itemId)}>
            {this.renderDownloadButton()}
          </div>
            {this.renderDeleteButton(type, item.id)}
          
        </li>
      );
    })
  }

  renderTableHeader = (type) => {
    const renderDate = () => {
      if (!this.state[type].hasDocDate) {
        return;
      }
      return <div className='t-header col-4 d-none d-md-flex d-lg-none d-xl-flex'> Data de Emissão</div>;
    };

    const renderDeleteColumn = () => {
      if (type !== 'customer') {
        return;
      }

      return (<div className="t-header col-2 col-sm-1"></div>);
    }
    return (
      <div className='table-header-container d-flex align-items-center'>
        <div className={`t-header ps-0 ${this.state[type].descriptionWidth}`}>Documento</div>
        {renderDate()}
        <div className={type !== 'customer' ? 't-header col' : 't-header col border-0'}>
          <span className='d-md-none d-lg-flex d-xl-none'> Download </span>
          <span className='d-none d-md-flex d-lg-none d-xl-flex'> Link Download </span>
        </div>
        {renderDeleteColumn()}
      </div>
    );
  }

  renderTable = (type) => {
    const { data } = this.state;
    if (!Helper.objectHasData(data[type])) {
      return (
        <div className='d-flex justify-content-center border-mask'>
          <div>{Labels.emptyFilesMessage}</div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.renderTableHeader(type)}
        <ul className='p-0 m-0'>{this.renderItems(data[type], type)}</ul>
      </React.Fragment>
    );
  }

  handleDownload = (url, filename, itemId) => {
    if (!Helper.isLiteVersion() || this.props.onCreateAppointment) {

      if (Helper.isIOSWebview()) {
        try {
          window.webkit.messageHandlers.downloadFile.postMessage(
            {
              auth_token: this.cookies.get('sessionToken'),
              route: this.cookies.get('route') ? this.cookies.get('route') : '',
              client_route: this.cookies.get('clientroute'),
              company: Helper.companyDomain(),
              file_name: filename,
              file_id: itemId
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else if (Helper.isAndroidWebview()) {
        try {
          window.Android.downloadFile(this.cookies.get('route'), this.cookies.get('clientroute'), Helper.companyDomain(), filename, itemId);
        } catch (error) {
          console.error(error);
        }
      } else {
        axios.get(url, { responseType: 'blob' }).then((res) => {
          fileDownload(res.data, filename)
        });
      }
    } else {
      this.renderModal(
        <Information
          title={Labels.liteSeeFileTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => (Helper.logoutFromLite())}
        />
      );
    }
  }

  handleUploadClick = () => {
    if (!Helper.isLiteVersion() || this.state.onCreateAppointment) {
      this.refInputFile.current.click();
    } else {
      this.renderModal(
        <Information
          title={Labels.liteUploadFileTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => (Helper.logoutFromLite())}
        />
      );
    }
  }

  handleUpdateMedia = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.props.newMediaFileLoaded(file);
    };
    reader.readAsDataURL(file);
  }

  renderUploadButton = (type) => {
    const { serviceState } = this.state;
    if (Helper.hideUploadButton(serviceState, type)) {
      return;
    }

    return (
      <div className="upload-button-container ms-2 mb-1" onClick={() => this.handleUploadClick()}>
        <label htmlFor="upload-photo" className='d-flex align-items-center lh-sm'>
          <img src={GENERAL.uploadBlue} className="icon-button me-1" alt="" role={'button'}/>
          {Labels.button.uploadDocumentButton}
        </label>
        <input ref={this.refInputFile}
          type="file" accept={Helper.documentFileAccept}
          onChange={this.handleUpdateMedia} />
      </div>
    );
  }

  renderList = (type) => {
    const subtitleClass = type === 'customer' ? 'normal-semibold-black' : 'mt-2 normal-semibold-black';
    return (
      <React.Fragment>
        <div className="row-list-title-container px-3 pt-3 w-100 d-inline-flex justify-content-between">
          <div className={subtitleClass}>
            {type === 'customer' ? Labels.documentsByCustomer : Labels.documentsByProvider}
          </div>
          {this.renderUploadButton(type)}
        </div>
        <div className="d-column-flex w-100">
          <div className="document-list-container p-3">
            {this.renderTable(type)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="row-list-container tab-right container-kss open mt-0 mb-0 pt-0">
        {!this.state.onCreateAppointment && this.renderList('provider')}
        {this.renderList('customer')}
        <Modal ref={this.refModal} />
      </div>
    );
  }

  renderModal = (view, param = null) => {
    this.refModal.current.renderView(view, param);
    this.refModal.current.openModal();
  }
}

export default DocumentList
