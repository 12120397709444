// React Imports
import React, { useEffect, useState } from 'react';
// Style
import './symptom_row.style.scss';
// Assets
import { GENERAL, SERVICE } from '../../../../assets/images/images';
import SelectComponent from '../selectComponent/SelectComponent';

const cLabels = {
  selectSpecialist: "Escolha um especialista:",
  placeholderSpecialist: "- Qualquer especialista -"
};

function SymptomRow(props) {
  const [idx] = useState(props.idx);
  const [active, setActive] = useState(props.service.active);
  const [symptom, setSymptom] = useState(props.service);
  const [selectedOption, setSelectedOption] = useState(props.selectedOption);
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setActive(props.service.active)
  },[props.service.active]);

  useEffect(() => {
    setSymptom(props.service);
  }, [props.service]);

  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props.selectedOption]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const handlerClick = () => {
    setActive(!active);
    props.onClick();
  };

  const onSymptomObsChange = (symptom, value) => {
    props.onSymptomObsChange(symptom, value);
  }

  const activeStatus = (active) => {
    return <img className='symptoms-row-status' src={active? SERVICE.serviceChecked : SERVICE.serviceNotChecked} alt="" />;
  };

  const renderSymptomObs = () => {
    if (active && symptom.obs_active) {
      return (
        <div className="observations-container mt-2 ms-3 me-3">
          <div className="observations-label normal-light-black">
            {symptom.obs_label}
          </div>
          <input
            type="text"
            maxLength="255"
            defaultValue={symptom.obs_value || ''}
            onChange={(e) => onSymptomObsChange(symptom, e.target.value)}
            className={`km-text-area smallwide`}
            placeholder=""
          />
        </div>
      );
    } else {
      return null;
    }
  }

  const renderExpert = () => {
    if (!(active && symptom.users && symptom.users.length > 0)) {
      return null;
    }
    return (
      <div className="observations-container mt-2 ms-3 me-3">
      <SelectComponent
        selectedOption={selectedOption ? { value: selectedOption.id, label: selectedOption.name } : null}
        onChange={props.onExpertChange}
        options={options}
        config={{
          isMulti: false,
          isClearable: true,
          autoFocus: false,
          isSearchable: false
        }}
        label={cLabels.selectSpecialist}
        placeholder={cLabels.placeholderSpecialist}
        classSelectLabel="normal-light-black"
        classSelectContainer="onSymptomListContainer"
      />
      </div>
    )
  }

  const renderIcon = () => {
    return !symptom?.video_call ? null : (
      <div className="icon-container flex-center" title='Possibilidade de Videochamada'><img src={GENERAL.iconVideoCall} alt="" /></div>
    );
  };
  
  return (
    <li key={idx} className='symptoms-row-container py-2' >
      <div onClick={handlerClick} className="row-content px-3">
        {activeStatus(active)}
        <div className={`symptoms-row-text text-align-left flex-start`}>
          {symptom?.name ? symptom.name.toLowerCase() : 'No designation'}
        </div>
        {renderIcon()}
      </div>
      {renderSymptomObs()}  
      {renderExpert()}
    </li>
    
    
  );
}

export default SymptomRow;
